module.exports = {
  "alifa.login.greeting1.label": "Welcome",
  "alifa.login.greeting2.label": "log in to your account",
  "alifa.login.forgotten.password.label": "Did you forget your password?",
  "alifa.login.placehold.password.label": "Enter Password",
  "alifa.login.placehold.email.label": "Enter Email",
  "alifa.login.credentials.validation.success.message": "User credentials correctly validated",
  "alifa.login.credentials.password.recovery.message": "A password recovery link has been delivered to your email address: ",
  "alifa.login.credentials.validation.failure.message": "An error occurred during credentials validation",
  "alifa.login.pleasewait.message": "Please wait",
  "alifa.login.access.message": "Access confirmed",
  "alifa.login.account.request.label": "Do you need an Alifa Cloud account?",
  "alifa.login.contact.request.label": "Contact Us",
  "alifa.login.username.required.message": "User email is required",
  "alifa.login.userpassword.required.message": "User password is required",
  "alifa.login.tooshort.password.message": "Password cannot be shorter than three characters",
  "alifa.addwidget.modal.okText": "OK",
  "alifa.addwidget.modal.cancelText": "Cancel",
  "alifa.addwidget.button.icon": "{prefix}",
  "alifa.addwidget.button.title": "Add Widget",
  "alifa.addwidget.save.title": "New widget save success",
  "alifa.addwidget.modal.title": "Add Widget",
  "alifa.addwidget.save.confirmation": "The new widget has been saved correctly and will be added to the current dashboard",
  "alifa.addwidget.save.error.title": "New widget save failure",
  "alifa.addwidget.save.error.notification": "The new widget has not been saved correctly -- the server encountered a blocking error",
  "alifa.addwidget.save.error.demo.notification": "The new widget has not been saved correctly -- this feature is disabled in demo mode",
  "alifa.removewidget.modal.okText": "OK",
  "alifa.removewidget.modal.cancelText": "Cancel",
  "alifa.removewidget.modal.text": "Are you sure you want to remove the widget from the current dashboard?",
  "alifa.dashboard.seeded.remove.error.title": "Seeded Dashboard",
  "alifa.dashboard.seeded.remove.error.notification": "The current Dashboard is a default and cannot be removed",
  "alifa.dashboard.remove.error.title": "Dashboard Removing Error",
  "alifa.dashboard.remove.error.notification": "The Dashboard cannot be removed",
  "alifa.dashboard.save.error.generic.title": "Dashboard Saving Generic Error",
  "alifa.dashboard.save.error.generic.notification": "The Dashboard cannot be saved because of unspecified error",
  "alifa.dashboard.save.error.title": "Dashboard Saving Error",
  "alifa.dashboard.save.error.duplication": "The selected Dashboard name already exist",
  "alifa.dashboard.update.error.title": "Dashboard Updating Error",
  "alifa.dashboard.update.error.notification": "The Dashboard cannot be updated",
  "alifa.dashboard.update.error.duplication": "The selected Dashboard name already exists",
  "alifa.dashboard.update.error.demo": "This action is disallowed in demo mode",
  "alifa.dashboard.update.error.generic": "The Dashboard update cannot be accomplished",
  "alifa.dashboard.update.confirmation": "The Dashboard has been correctly updated",
  "alifa.dashboard.update.title": "Dashboard Updating Confirmation",
  "alifa.dashboard.remove.confirmation.question": "Are sure you want to delete this Dashboard and its related Widgets?",
  "alifa.dashboard.remove.title": "Remove Dashboard",
  "alifa.dashboard.save.confirmation": "The Dashboard has been saved correctly",
  "alifa.dashboard.remove.confirmation": "The Dashboard has been removed correctly",
  "alifa.dashboard.save.title": "Save Dashboard",
  "alifa.dashboard.add.title": "Add New Dashboard",
  "alifa.dashboard.add.sidebar.title": "Add Dashboard",
  "alifa.dashboard.rename.title": "Rename Dashboard",
  "alifa.userinfo.plantype": "Your Plan Info",
  "alifa.userinfo.planexpiration": "Expiration",
  "alifa.widget.nodata.message": "No Data Avaliable",
  "alifa.widget.menu.removechart": "Remove Chart",
  "alifa.widget.date.localization": "Date",
  "alifa.widget.value.localization": "Value",
  "alifa.widget.current.localization": "Current",
  "alifa.widget.minimum.localization": "Minimum",
  "alifa.widget.maximum.localization": "Maximum",
  "alifa.widget.day.localization": "Day",
  "alifa.widget.week.localization": "Week",
  "alifa.widget.month.localization": "Month",
  "alifa.widget.remove.title": "Widget remove success",
  "alifa.widget.remove.confirmation": "The widget has been removed correctly",
  "alifa.widget.lastvalue.info": "Showing {start} to {end} of {rows} entries",
  "alifa.widget.lastvalue.norows": "No entries to found",
  "alifa.widget.lastvalue.filter": "Filter",
  "alifa.widget.lastvalue.previous": "Previous",
  "alifa.widget.lastvalue.next": "Next",
  "alifa.report.daterange.label": "Date Range",
  "alifa.report.periodselection.label": "Period Selection",
  "alifa.report.timenavigator.label": "Time Navigator",
  "alifa.report.lastday.text": "Last Day",
  "alifa.report.last7days.text": "Last 7 Days",
  "alifa.report.last14days.text": "Last 14 Days",
  "alifa.report.last30days.text": "Last 30 Days",
  "alifa.report.custom.text": "Custom",
  "alifa.report.save.button.text": "Save",
  "alifa.report.datagrid.button.text": "Data Grid",
  "alifa.report.update.button.text": "Update",
  "alifa.report.clearall.button.text": "Clear All",
  "alifa.report.loading.message": "Loading Data Grid...",
  "alifa.report.nodatafound.message": "No Data Found",
  "alifa.report.nodatafound.description": "No items for the period",
  "alifa.report.field.validation": "Please input a valid value for the field",
  "alifa.report.select.title": "Select Measures",
  "alifa.report.daterange.title": "Date Range Restriction Applied",
  "alifa.report.daterange.message": "The report period selection is restricted to one month only; any date selected before or after the current month will be ignored",
  "alifa.report.nomeasures.title": "No measures selected",
  "alifa.report.nomeasures.message": "The report submission require at least one measure selected; please select one or more station measure flagging from the list above",
  "alifa.menu.newdashboard.item": "Add Dashboard",
  "alifa.menu.reports.item": "Reports",
  "alifa.menu.datalogger.item": "Datalogger",
  "alifa.menu.profile.item": "Profile",
  "alifa.menu.settings.item": "Settings",
  "alifa.menu.quit.item": "Quit",
  "alifa.menu.admin.item": "Admin",
  "alifa.menu.manageusers.item": "Manage Users",
  "alifa.menu.managedataloggers.item": "Manage Dataloggers",
  "alifa.menu.managemeasures.item": "Manage Measures",
  "alifa.addwidget.station.field": "Station",
  "alifa.addwidget.measure.field": "Measure",
  "alifa.addwidget.charttype.field": "Chart Type",
  "alifa.addwidget.description.field": "Description", 
  "alifa.settings.readonly.tooltip": "This information is read-only as it cannot be changed",
  "alifa.settings.save.label": "Save",
  "alifa.settings.supportcall.text": "Having trouble accessing the Alifa Cloud FTP server?",
  "alifa.settings.contactus.text": "Contact Us",
  "alifa.settings.ftpusername.label": "FTP Username",
  "alifa.settings.ftppassword.label": "FTP Password",
  "alifa.settings.ftpaddress.label": "FTP Address",
  "alifa.settings.ftpport.label": "FTP Port",
  "alifa.profile.newpassword.form.title": "Change user password",
  "alifa.profile.newpassword.okbutton.label": "Ok",
  "alifa.profile.newpassword.cancelbutton.label": "Cancel",
  "alifa.profile.currentpassword.label": "Current password",
  "alifa.profile.newpassword.label": "New password",
  "alifa.profile.confirmpassword.label": "Confirm password",
  "alifa.profile.currentpassword.placeholder": "Insert the current password",
  "alifa.profile.newpassword.placeholder": "Insert a new password",
  "alifa.profile.confirmpassword.placeholder": "Confirm the new password",
  "alifa.profile.currentpassword.validation.message": "Please insert the current password",
  "alifa.profile.newpassword.validation.message": "Please insert a new password",
  "alifa.profile.confirmpassword.validation.message": "Please insert insert the same password inserted into the previous field",
  "alifa.profile.newpassword.failure.title": "New password validation failed",
  "alifa.profile.newpassword.failure.description": "The new password should match with confirm password -- the password change operation will be aborted",
  "alifa.profile.email.tooltip": "Modify User Email",
  "alifa.profile.username.tooltip": "User name is readonly -- this field cannot be modified",
  "alifa.profile.password.tooltip": "Modify password",
  "alifa.profile.numberformat.tooltip": "This formatting field influence digists displayed during charts rendering",
  "alifa.profile.timezone.tooltip": "This list of timezone offsets defines the time zone applied when populating chart axis",
  "alifa.profile.language.tooltip": "This list defines the language used for the current user",
  "alifa.profile.dtformat.tooltip": "This list of ISO-8601 formats defines the formatting of dates -- For each format the current date is shown as an example applied as formatted",
  "alifa.profile.firstname.label": "First Name",
  "alifa.profile.lastname.label": "Last Name",
  "alifa.profile.email.label": "Email",
  "alifa.profile.username.label": "User Name",
  "alifa.profile.password.label": "Password",
  "alifa.profile.numberformat.label": "Number Format",
  "alifa.profile.timezone.label": "Timezone",
  "alifa.profile.language.label": "Language",
  "alifa.profile.dtformat.label": "DateTime Format",
  "alifa.profile.submit.label": "Save",
  "alifa.profile.changepassword.error.demo.title": "Disabled feature",
  "alifa.profile.changepassword.error.demo.description": "This feature is disabled in DEMO mode.",
  "alifa.profile.changepassword.submit.success.title": "Confirmation Server Message",
  "alifa.profile.changepassword.submit.success.description": "User password saving completed correctly",
  "alifa.profile.changepassword.submit.failure.title": "Failure Server Message",
  "alifa.profile.changepassword.submit.failure.description": "User data saving failed -- the new password cannot be updated",
  "alifa.profile.userinfo.submit.success.title": "Confirmation Server Message",
  "alifa.profile.userinfo.submit.success.description": "User data saving completed correctly",
  "alifa.profile.userinfo.submit.failure.title": "Failure Server Message",
  "alifa.profile.userinfo.submit.failure.description": "User data saving failed -- the operation cannot be completed",
  "alifa.dashboard.test": "test"
};